<template>
  <div id="overlay" v-if="overlay"></div>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1"><h3>Entidades Conalot</h3></div>
        </div>
      </div>
    </div>
  </div>
  <h1 v-if=loading>
    <div class="flex justify-content-center">
      <ProgressSpinner/>
    </div>
  </h1>
  <form @submit.prevent="saveData" class="p-fluid" autocomplete="off">
    <Card>
      <template #title>
        Bloques
      </template>
      <template #content>
        <PickList v-model="comercializadores" dataKey="id_comercializador"
                  :responsive=true
                  @selectionChange="clicked"
                  class="bloques"
                  :stripedRows=false>

          <template #sourceheader>
            <div class="flex justify-content-center">
              NO VISIBLES
            </div>
          </template>
          <template #targetheader>
            <div class="flex justify-content-center">
              VISIBLES
            </div>
          </template>

          <template #item="slotProps">
            <div class="p-caritem">
              <p>{{ slotProps.item.tcomercializador }}</p>
            </div>
          </template>
        </PickList>
      </template>
    </Card>




    <!--Bancas -->
    <div v-if="bancas">
      <Divider/>
      <Card>
        <template #title>
          Bancas
        </template>
        <template #content>
          <PickList v-model="bancas" dataKey="id_banca"
                    class="bloques"
                    :stripedRows=false>
            <template #sourceheader>
              <div class="flex justify-content-center">
                NO VISIBLES
              </div>
            </template>
            <template #targetheader>
              <div class="flex justify-content-center">
                VISIBLES
              </div>
            </template>
            <template #item="slotProps">
              <div class="p-caritem">
                <p>{{ slotProps.item.tbanca }}</p>
              </div>
            </template>
          </PickList>
        </template>
      </Card>


    </div>

    <div class="flex justify-content-end" style="margin-top: 10px;">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="!bDirty"
      />
    </div>
  </form>
  <Toast/>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "Main",
  components: {
    'ProgressSpinner': ProgressSpinner,
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.roleId = this.$store.getters.getProfile.role_id;
    this.entityId = this.$store.getters.getProfile.entity_id;
    this.theDate = this.getYesterdayString();
  },
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      comercializadores: null,
      bancas: null,
      bDirty: true,
      selectedComercializador: null,
      loading: false,
      overlay: false,
    };
  },
  methods: {
    async loadData() {
      this.overlay = true;
      let comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        entity_id: this.entityId,
        comercializador: this.selectedComercializador,
        hide_external: true,
        page: 'conalot',
      });


      let conalotData = await this.mainService.getConalotEntities();

      if (conalotData) {
        conalotData.comercializador.forEach((item) => {
          comercializadores = comercializadores.filter(val => val.id_comercializador !== item.id_comercializador);
        });
      }
      // conalotData.bancas.forEach((item) => {
      //   bancas = bancas.filter(val => val.id_banca !== item.id_banca);
      // });

      this.comercializadores = [comercializadores, conalotData.comercializador];
      // this.bancas = [bancas, conalotData.bancas];
      this.overlay = false;
    },
    saveData() {
      this.mainService.setConalotEntities({
        selectedComercializador: this.selectedComercializador,
        id_bancas: this.bancas[1],
      });
      this.$toast.add({
        severity: 'success',
        summary: ``,
        detail: `Cambios grabados`,
        life: 3000
      });
    },
    async clicked(e) {
      this.overlay = true;
      if (e.value[1][0] && e.value[1][0].id_comercializador) {
        this.selectedComercializador = e.value[1][0].id_comercializador;

        let bancas = await this.mainService.getBanca({
          fecha: this.theDate,
          entity_id: this.entityId,
          id_comercializador: this.selectedComercializador,
          page: 'agencies',
          conalot: true,
        });

        let conalotData = await this.mainService.getConalotEntities({id_comercializador: this.selectedComercializador});

        if (conalotData.banca) {
          conalotData.banca.forEach((item) => {
            bancas = bancas.filter(val => val.id_banca !== item.id_banca);
          });
        }
        this.bancas = [bancas, conalotData.banca];
      }
      this.overlay = false;
    }
  },

}
</script>

<style lang="scss" scoped>

::v-deep(.bloques .p-picklist-source-controls, .p-picklist-target-controls)  {
  display: none !important;
}
::v-deep(.bloques .p-picklist-target-controls) {
  display: none !important;
}
::v-deep(.bancas .p-picklist-source-controls, .p-picklist-target-controls)  {
  display: none !important;
}
::v-deep(.bancas .p-picklist-target-controls) {
  display: none !important;
}
</style>